import { LoggerFactory } from '../../logger';
import { ILibraryManager } from './ILibraryManager';
import { ILibrary } from './ILibrary';
import { Library } from './library';
import { IClientSettings, HostedClientLoadedEvent, ILogger } from '@sparkware/uc-sdk-core';

class LibraryManager implements ILibraryManager {
    readonly ClientsFrameworkLibrary: ILibrary<void>;
    readonly UserareaLibrary: ILibrary<void>;
    readonly UCSDKLibrary: ILibrary<unknown>;
    readonly UCSDKEntryLibrary: ILibrary<unknown>;
    readonly ClientLibrary: ILibrary<HostedClientLoadedEvent<IClientSettings>>;

    constructor(logger: ILogger) {
        this.ClientsFrameworkLibrary = new Library('ClientsFramework', logger);
        this.UserareaLibrary = new Library('Userarea', logger);
        this.UCSDKLibrary = new Library('UCSDK', logger);
        this.UCSDKEntryLibrary = new Library('UCSDKEntry', logger);
        this.ClientLibrary = new Library('Client', logger);
    }
}

const logger = LoggerFactory.create('LibraryManager', window.console);

const Instance: ILibraryManager = new LibraryManager(logger);

export const {
    ClientsFrameworkLibrary,
    UCSDKEntryLibrary,
    UCSDKLibrary,
    UserareaLibrary,
    ClientLibrary,
} = Instance;

export default Instance;
